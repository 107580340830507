import * as React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminRoutes from "./AdminRoutes";
import FlowsRoutes from "./FlowsRoutes";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin" component={AdminRoutes} />
        <Route component={FlowsRoutes} />
      </Switch>
    </BrowserRouter>
  );
};
export default Router;
