/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Button, CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import ConnectContainer from "components/connect/ConnectContainer";
import Hero from "components/onboarding/Hero";
import {
  useAthenaCsvMutation,
  useFlowMoveBackMutation,
  useAthenaPresignedUrlQuery,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import ShareAthenaResults from "components/athena/ShareAthenaResults";
import { usePresignedUpload } from "api/athena.service";

const ConnectAthenaCSVPage: React.FC = () => {
  const { flow, profileQuery } = useAuth();
  const [csv, setCSVContents] = React.useState<File | null>(null);
  const {
    isLoading: isBackLoading,
    mutateAsync: backMutate,
  } = useFlowMoveBackMutation();
  const {
    isLoading: uploadIsLoading,
    mutateAsync: uploadMutate,
  } = usePresignedUpload();
  const {
    mutateAsync: csvMutate,
    isLoading: csvIsLoading,
  } = useAthenaCsvMutation();

  const presignedQuery = useAthenaPresignedUrlQuery(
    { flowId: flow?.id || "" },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleBack = async () => {
    await backMutate({ flowId: flow?.id || "" });
    await profileQuery?.refetch();
  };
  const handleUpload = () => {
    uploadMutate({
      uploadUrl: presignedQuery.data?.athena?.url || "",
      file: csv,
    }).then(() => {
      csvMutate({ flowId: flow?.id || "" });
      profileQuery?.refetch();
    });
  };
  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            xs={12}
            item
            container
            justify="center"
            alignItems="flex-end"
            sx={{ px: 4 }}
            direction="row"
          >
            <Grid
              item
              container
              direction="column"
              justify="space-between"
              xs={6}
            >
              <Grid item sx={{ px: 3 }}>
                <Hero title="Share results of your query" />
              </Grid>
            </Grid>
            <Grid xs={6} item>
              <ShareAthenaResults
                onFileSelected={setCSVContents}
                isLoading={uploadIsLoading || csvIsLoading}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignContent="flex-end"
            justify="space-between"
            sx={{ pb: 4, px: 4 }}
          >
            <Grid item xs={2}>
              <Button
                onClick={handleBack}
                variant="text"
                size="medium"
                color="primary"
                disableElevation
                fullWidth
                sx={{
                  textAlign: "left",
                  mt: 2,
                  width: "100%",
                }}
              >
                {isBackLoading ? (
                  <CircularProgress size={26} color="primary" />
                ) : (
                  "Back"
                )}
              </Button>
            </Grid>
            <Grid item xs={6} container direction="row" justify="flex-end">
              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={handleUpload}
                disabled={presignedQuery.isLoading}
                disableElevation
                sx={{ width: "50%" }}
              >
                {uploadIsLoading ? (
                  <CircularProgress size={26} color="inherit" />
                ) : (
                  "Upload to Virtasant"
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
export default ConnectAthenaCSVPage;
