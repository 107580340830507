/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import DiagnosticsAthenaResults from "components/athena/DiagnosticsAthenaResults";
import DiagnosticResults from "components/diagnostic/DiagnosticResults";
import { FlowStage } from "generated/graphql";
import { SignUpRow } from "pages/admin/AdminSignUpsPage";
import * as React from "react";

type Props = {
  onClose: () => void;
  onNext: () => void;
  onPrevious: () => void;
  rowData?: SignUpRow;
};

const AdminDiagResultsPreview: React.FC<Props> = ({
  onNext,
  onPrevious,
  onClose,
  rowData,
}) => {
  const isOpen = !!rowData;
  const flowId = rowData?.actions.flowId;
  const isAthena = rowData?.actions.isAthena;
  const onSendResultsClick = rowData?.actions.onSendResultsClick;
  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <DialogTitle>
        <Container
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3">Preview of Results</Typography>
          <Box>
            <Button onClick={onPrevious} variant="contained" sx={{ ml: 3 }}>
              Previous
            </Button>
            <Button onClick={onNext} variant="contained" sx={{ ml: 3 }}>
              Next
            </Button>
          </Box>
        </Container>
      </DialogTitle>
      <DialogContent>
        {rowData && (
          <Container>
            <Grid container spacing={3} sx={{ pb: 30 }}>
              <Grid item xs={6} sm={3}>
                <Typography>Name:</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography data-testid="modal-username">
                  {rowData.userName}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>Total Spend:</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>{rowData.totalCost}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>Company:</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>{rowData.companyName}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>Total Savings:</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>{rowData.totalSavings}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>Flow setup Type:</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>{rowData.flowSetupType}</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>Stage:</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Typography>{rowData.flowStage}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Button onClick={onClose} variant="contained">
                  Back to Summary
                </Button>
              </Grid>
              <Grid item xs={6}>
                {!isAthena && rowData.flowStage === FlowStage.Processing && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onSendResultsClick}
                  >
                    Approve Results
                  </Button>
                )}
              </Grid>
            </Grid>
          </Container>
        )}
        {flowId &&
          (isAthena ? (
            <DiagnosticsAthenaResults
              flowId={flowId}
              userName={rowData?.userName}
            />
          ) : (
            <DiagnosticResults flowId={flowId} userName={rowData?.userName} />
          ))}
      </DialogContent>
    </Dialog>
  );
};
AdminDiagResultsPreview.displayName = "AdminDiagResultsPreview";
export default AdminDiagResultsPreview;
