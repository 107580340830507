// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib-esm/types";
import Amplify, { Auth, Hub } from "aws-amplify/lib-esm";
import React, { useContext } from "react";
import env from "utils/env";
import useAdminToken from "./use-admin-token";

const awsConfig = {
  Auth: {
    region: env("REACT_APP_REGION"),
    userPoolId: env("REACT_APP_COGNITO_POOL_ID"),
    userPoolWebClientId: env("REACT_APP_COGNITO_WEB_CLIENT_ID"),
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: env("REACT_APP_COGNITO_DOMAIN"),
      scope: ["email", "profile", "openid"],
      redirectSignIn: env("REACT_APP_COGNITO_CALLBACK_URL"),
      redirectSignOut: env("REACT_APP_COGNITO_CALLBACK_URL"),
      responseType: "code",
    },
  },
};

interface AdminAuthContextValue {
  isAuthenticated: boolean;
  isLoading: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}

export const AdminAuthContext = React.createContext<AdminAuthContextValue>({
  isAuthenticated: false,
  isLoading: true,
  login: async () => {},
  logout: async () => {},
});

export interface LoginResponse {
  api_key: string;
}

export const AdminAuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useAdminToken();
  const [isLoading, setLoading] = React.useState(true);
  const login = React.useCallback(async () => {
    Auth.federatedSignIn({
      provider: env(
        "REACT_APP_COGNITO_SAML_PROVIDER"
      ) as CognitoHostedUIIdentityProvider,
    });
  }, []);
  const logout = React.useCallback(async () => {
    setToken(null);
    localStorage.clear();
    // TODO: use cognito logout
    // await Auth.signOut({ global: true }); // https://github.com/aws-amplify/amplify-js/issues/8514#issuecomment-871381909
    window.location.reload();
  }, [setToken]);

  React.useEffect(() => {
    Amplify.configure(awsConfig);
    const getToken = async () => {
      try {
        setToken((await Auth.currentSession()).getAccessToken().getJwtToken());
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Not signed in");
        setToken(null);
      } finally {
        setLoading(false);
      }
    };
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          getToken();
          break;
        case "signOut":
          setToken(null);
          break;
        default: {
        }
      }
    });

    getToken();
  }, [setToken]);

  const contextValue: AdminAuthContextValue = React.useMemo(() => {
    return {
      isAuthenticated: !!token,
      isLoading,
      login,
      logout,
    };
  }, [isLoading, login, logout, token]);

  return (
    <AdminAuthContext.Provider value={contextValue}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = (): AdminAuthContextValue => {
  return useContext(AdminAuthContext);
};
