/** @jsxImportSource theme-ui */
import { Box, Container, Typography } from "@material-ui/core";
import diagnosticBg from "assets/diagnostic-bg.jpg";
import * as React from "react";

type Props = {
  className?: string;
};

const DiagnosticError: React.FC<Props> = () => {
  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, rgba(26, 179, 198, 0.9) 3.49%, #004F9E 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 100%), url(${diagnosticBg})`,
        backgroundSize: "contain",
        minHeight: "100vh",
      }}
    >
      <Container
        sx={{
          pt: 130,
          color: "white",
        }}
      >
        <Typography variant="h2">Error</Typography>
        <Typography variant="h5" component="p">
          An unexpected error has occurred, please contact us at{" "}
          <a href="mailto:support@virtasant.com">support@virtasant.com</a>
        </Typography>
      </Container>
    </Box>
  );
};
DiagnosticError.displayName = "DiagnosticError";
export default DiagnosticError;
