import FullScreenLoader from "components/general/FullScreenLoader";
import DashboardLayout from "components/layout/DashboardLayout";
import { AdminAuthProvider, useAdminAuth } from "hooks/use-admin-auth";
import AdminLoginPage from "pages/admin/AdminLoginPage";
import AdminSignUps from "pages/admin/AdminSignUpsPage";
import * as React from "react";
import { Route, Switch } from "react-router-dom";

const AdminInnerRoutes: React.FC = () => {
  const { isLoading, isAuthenticated, logout } = useAdminAuth();
  if (isLoading) {
    return <FullScreenLoader />;
  }
  if (!isAuthenticated) {
    return <AdminLoginPage />;
  }
  return (
    <DashboardLayout handleSignout={logout}>
      <Switch>
        <Route exact path="/admin" component={AdminSignUps} />
      </Switch>
    </DashboardLayout>
  );
};

const AdminRoutes: React.FC = () => {
  return (
    <AdminAuthProvider>
      <AdminInnerRoutes />
    </AdminAuthProvider>
  );
};
AdminRoutes.displayName = "AdminRoutes";
export default AdminRoutes;
