/** @jsxImportSource theme-ui */
import { Grid } from "@material-ui/core";
import NavBar from "components/navigation/NavBar";
import * as React from "react";

type DashboadLayoutProps = {
  handleSignout?: () => void;
};

const DashboardLayout: React.FC<DashboadLayoutProps> = ({
  children,
  handleSignout,
}) => {
  return (
    <Grid container direction="column">
      <NavBar theme="dark" handleSignout={handleSignout} />
      <main>{children}</main>
    </Grid>
  );
};
export default DashboardLayout;
