import useToken from "hooks/use-token";
import { useSnackbar } from "notistack";
import * as React from "react";
import env from "utils/env";
import { ResponseError } from "../api/errors";
import useAdminToken from "./use-admin-token";

const GRAPHQL_API = env("REACT_APP_BASE_URL");

export const useFetchData = <TData, TVariables>(
  query: string
): (() => Promise<TData>) => {
  const [token] = useToken();
  const [adminToken] = useAdminToken();
  const { enqueueSnackbar } = useSnackbar();
  const headers: HeadersInit = React.useMemo(() => {
    const newHeaders: HeadersInit = {};
    if (token || adminToken) {
      newHeaders.Authorization = `Bearer ${token || adminToken}`;
    }
    return newHeaders;
  }, [token, adminToken]);
  return React.useCallback(
    async (variables?: TVariables) => {
      try {
        const res = await fetch(GRAPHQL_API, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
          body: JSON.stringify({
            query,
            variables,
          }),
        });

        if (!res.ok) {
          enqueueSnackbar(res.statusText || "An error occurred", {
            variant: "error",
          });
          throw new ResponseError({ serverError: res });
        }

        const json = await res.json();

        if (json.errors) {
          throw new ResponseError({ graphqlErrors: json.errors });
        }

        return json.data;
      } catch (e) {
        if (e instanceof ResponseError) {
          throw e;
        }
        enqueueSnackbar(e.message || "An error occurred", { variant: "error" });
        throw new ResponseError({ networkError: e.message });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headers, query]
  );
};
