/** @jsxImportSource theme-ui */
import * as React from "react";

import { Button, Grid, Typography } from "@material-ui/core";
import VerifyAthenaSetupForm from "components/verify-setup/VerifyAthenaSetupForm";
import { useFlowMoveBackMutation } from "generated/graphql";
import { useAuth } from "hooks/use-auth";

const VerifyAWSSetup: React.FC = () => {
  const { profileQuery, flow } = useAuth();
  const backMutation = useFlowMoveBackMutation();

  const handleBack = async () => {
    await backMutation.mutateAsync({
      flowId: flow?.id || "",
    });
    profileQuery?.refetch();
  };
  return (
    <Grid
      container
      sx={{
        bg: "white",
        borderRadius: 10,
        minHeight: 515,
        maxWidth: 1240,
        mx: "auto",
      }}
    >
      <Grid
        item
        sm={4}
        md={6}
        container
        justify="center"
        alignItems="stretch"
        sx={{ position: "relative" }}
      >
        <Grid
          item
          container
          alignItems="center"
          sx={{
            flexGrow: 0,
            flexBasis: [, "460px"],
            position: "relative",
          }}
        >
          <Grid item sx={{ pl: [30, , 0] }}>
            <Typography
              component="h1"
              variant="h2"
              sx={{
                color: "#EF7B2E",
              }}
            >
              Verify your Athena Setup
            </Typography>
          </Grid>
        </Grid>
        <Button
          type="button"
          variant="text"
          color="primary"
          onClick={handleBack}
          size="large"
          sx={{
            px: 4,
            mt: [4, 0],
            position: [, "absolute"],
            left: [, 80],
            bottom: [, 20],
          }}
        >
          Back
        </Button>
      </Grid>
      <Grid item sm={8} md={6}>
        <VerifyAthenaSetupForm />
      </Grid>
    </Grid>
  );
};
VerifyAWSSetup.displayName = "VerifyAWSSetup";
export default VerifyAWSSetup;
