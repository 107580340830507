/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Grid, Typography, Link } from "@material-ui/core";
import React from "react";
import ConnectContainer from "components/connect/ConnectContainer";
import Hero from "components/onboarding/Hero";
import {
  useAthenaShowSqlMutation,
  useAthenaSqlQueryQuery,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import { ToSDialog } from "components/general/ToS";
import ConnectAthenaSQL from "components/athena/ConnectAthenaSQL";

const HeroSubtitle: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      <Typography sx={{ mt: 4 }}>
        Please review
        <Link
          component="button"
          variant="body1"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setOpen(true);
          }}
          sx={{ ml: 2 }}
        >
          Terms of Service.
        </Link>
      </Typography>
      <ToSDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
const ConnectAthenaSQLPage: React.FC = () => {
  const { flow, profileQuery } = useAuth();
  const athenaSQLQuery = useAthenaSqlQueryQuery({ flowId: flow?.id || "" });
  const athenaSQLMutation = useAthenaShowSqlMutation();
  const handleSubmit = async () => {
    await athenaSQLMutation.mutateAsync({ flowId: flow?.id || "" });
    await profileQuery?.refetch();
  };
  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            sm={4}
            md={6}
            lg={6}
            item
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Using the Athena Query">
                <HeroSubtitle />
              </Hero>
              <Typography variant="body1" sx={{ mt: 4, fontWeight: 400 }}>
                Using the information you have provided, the tool has generated
                a query for you to review and run from the Athena console.
                Please log into the the AWS Payer account and run the query to
                generate a CSV file of the CUR data.
              </Typography>
            </Grid>
          </Grid>
          <ConnectAthenaSQL
            sql={athenaSQLQuery.data?.athena?.sql || ""}
            onSubmit={handleSubmit}
            loading={athenaSQLMutation.isLoading}
          />
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
export default ConnectAthenaSQLPage;
