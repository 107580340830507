/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import numbro from "numbro";
import * as React from "react";
import DiagnosticPieChartComponent, {
  PieChartData,
} from "./DiagnosticPieChartComponent";

type Props = {
  className?: string;
  opportunitiesIdentifiedAmount: number;
  pieChartData: PieChartData;
  totalSpendAmount: number;
};

const DiagnosticPieChart: React.FC<Props> = ({
  className,
  opportunitiesIdentifiedAmount,
  pieChartData,
  totalSpendAmount,
}) => {
  const cloudCosts =
    totalSpendAmount === 0
      ? "0 %"
      : numbro(opportunitiesIdentifiedAmount / totalSpendAmount).format({
          output: "percent",
          mantissa: 0,
        });
  return (
    <Box className={className} sx={{ mt: [80, 0] }}>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          fontSize: [28, , 32],
          lineHeight: ["36px", , "50px"],
          textAlign: ["center", , "left"],
        }}
      >
        {/* Out of the{" "}
        <Typography
          variant="h2"
          component="span"
          sx={{
            fontSize: [36, , 42],
            lineHeight: ["32px", , "50px"],
            color: "#ABDE4D",
          }}
        >
          {analyzedPercent}
        </Typography>{" "}
        analyzed these are the opportunities we’ve identified.
        */}
        {`Great news! Based on our initial analysis, we can save you ${cloudCosts} on your cloud costs, automatically.`}
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          mt: [36, 50],
          alignItems: "center",
          pb: 10,
          flexDirection: ["column-reverse", , "row"],
        }}
      >
        <Grid
          item
          container
          md={3}
          justify="space-between"
          sx={{
            mt: [30, 0],
            textAlign: "center",
            height: [, , 260],
            flexDirection: ["column", "row", "column"],
          }}
        >
          <Grid item>
            <Typography
              sx={{
                fontSize: 12,
                color: "#E1F2F9",
                lineHeight: "16px",
                mb: 2,
              }}
            >
              Total Annualized Spend
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontSize: 28,
                lineHeight: "32px",
                fontWeight: 600,
              }}
            >
              $
              {numbro(totalSpendAmount).format({
                thousandSeparated: true,
                optionalMantissa: true,
                mantissa: 2,
              })}
            </Typography>
            <Typography>USD</Typography>
          </Grid>
          <Grid item sx={{ mt: [36, 0] }}>
            <Typography
              sx={{
                fontSize: 12,
                color: "#E1F2F9",
                lineHeight: "16px",
                mb: 2,
              }}
            >
              Savings Opportunities Identified (Annualized Value)
            </Typography>
            <Typography
              variant="h3"
              sx={{
                fontSize: 28,
                lineHeight: "32px",
                fontWeight: 600,
              }}
            >
              $
              {numbro(opportunitiesIdentifiedAmount).format({
                thousandSeparated: true,
                optionalMantissa: true,
                mantissa: 2,
              })}
            </Typography>
            <Typography>USD</Typography>
          </Grid>
        </Grid>
        <Grid item md={9} sx={{ width: ["100%", , "unset"] }}>
          <DiagnosticPieChartComponent data={pieChartData} />
        </Grid>
      </Grid>
    </Box>
  );
};
DiagnosticPieChart.displayName = "DiagnosticPieChart";
export default DiagnosticPieChart;
