/** @jsxImportSource theme-ui */
import DiagnosticsAthenaResults from "components/athena/DiagnosticsAthenaResults";
import DiagnosticError from "components/diagnostic/DiagnosticError";
import { useAuth } from "hooks/use-auth";
import * as React from "react";

const DiagnosticsAthenaPage: React.FC = () => {
  const { user, flow } = useAuth();
  const flowId = flow?.id;
  if (!flowId) {
    return <DiagnosticError />;
  }
  return <DiagnosticsAthenaResults flowId={flowId} userName={user?.name} />;
};
DiagnosticsAthenaPage.displayName = "DiagnosticsAthenaPage";
export default DiagnosticsAthenaPage;
