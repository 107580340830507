/** @jsxImportSource theme-ui */
import { Box, Grid, Typography } from "@material-ui/core";
import diagnosticBg from "assets/diagnostic-bg.jpg";
import { BarChartData } from "components/diagnostic/BarChartComponent";
import DiagnosticCTA from "components/diagnostic/DiagnosticCTA";
import DiagnosticError from "components/diagnostic/DiagnosticError";
import DiagnosticPieChart from "components/diagnostic/DiagnosticPieChart";
import { PieChartData } from "components/diagnostic/DiagnosticPieChartComponent";
import SavingOpportunities from "components/diagnostic/SavingOpportunities";
import Top3Tickets, { TopTicket } from "components/diagnostic/Top3Tickets";
import FullScreenLoader from "components/general/FullScreenLoader";
import { format } from "date-fns";
import { useDiagnosticDashboardQuery } from "generated/graphql";
import * as React from "react";
import { nbOr0, nbToPercent } from "utils/numbers";

type DiagnosticResultsProps = {
  flowId: string;
  userName?: string | null;
};

const DiagnosticResults: React.FC<DiagnosticResultsProps> = ({
  flowId,
  userName,
}) => {
  const diagnosticDashboardQuery = useDiagnosticDashboardQuery({
    flowId,
  });
  const diagnosticDashboardData = diagnosticDashboardQuery.data;
  const pieChartData: PieChartData = React.useMemo(() => {
    return (
      diagnosticDashboardData?.diagnostics?.services
        ?.filter(
          (opportunity) => opportunity?.savings && opportunity.savings > 0
        )
        .map((opportunity) => {
          if (!opportunity)
            throw new Error("API Opportunity should be defined");
          const { cloudService, savings } = opportunity;
          return {
            name: cloudService?.shortName || "others",
            value: nbOr0(savings),
          };
        }) || []
    );
  }, [diagnosticDashboardData]);

  const totalSpent: number = React.useMemo(() => {
    return diagnosticDashboardData?.diagnostics?.annualCost || 0;
  }, [diagnosticDashboardData]);
  const totalSavings: number = React.useMemo(() => {
    return (
      diagnosticDashboardData?.diagnostics?.services?.reduce(
        (acc, opportunity) => {
          if (!opportunity)
            throw new Error("API Opportunity should be defined");
          return acc + nbOr0(opportunity.savings);
        },
        0
      ) || 0
    );
  }, [diagnosticDashboardData]);

  const barChartData: BarChartData = React.useMemo(() => {
    return (
      diagnosticDashboardData?.diagnostics?.services
        ?.filter(
          (opportunity) => opportunity?.savings && opportunity.savings > 0
        )
        .map((opportunity) => {
          if (!opportunity)
            throw new Error("API Opportunity should be defined");
          return {
            name: opportunity?.cloudService?.shortName || "others",
            a:
              Math.round(
                100 * (nbOr0(opportunity?.cost) - nbOr0(opportunity?.savings))
              ) / 100,
            b: Math.round(100 * nbOr0(opportunity?.savings)) / 100,
          };
        }) || []
    );
  }, [diagnosticDashboardData]);
  const totalTickets = React.useMemo(
    () => diagnosticDashboardData?.diagnostics?.opportunitiesCount || 0,
    [diagnosticDashboardData]
  );
  const topTickets: TopTicket[] = React.useMemo(() => {
    return (
      diagnosticDashboardData?.diagnostics?.top?.map((opportunity) => {
        if (!opportunity) throw new Error("API Opportunity should be defined");
        const { playbook, region, resourceId, savings } = opportunity;
        const title = playbook?.titleDiagnostics;
        const rationale = playbook?.details?.description;
        return {
          title: title || "",
          description: rationale || undefined,
          resourceID: resourceId || "",
          region: region || "",
          opportunity: nbOr0(savings),
        };
      }) || []
    );
  }, [diagnosticDashboardData]);

  const endDate: Date | undefined | null =
    diagnosticDashboardData?.process?.end;
  const formattedEndDate = endDate && format(new Date(endDate), "MM/dd/yyyy");
  const formattedEndTime = endDate && format(new Date(endDate), "hh:mm aaaa");

  if (diagnosticDashboardQuery.isLoading) {
    return <FullScreenLoader />;
  }
  if (diagnosticDashboardQuery.isError) {
    return <DiagnosticError />;
  }

  return (
    <Box>
      <Grid
        container
        sx={{
          background: `linear-gradient(180deg, rgba(26, 179, 198, 0.9) 3.49%, #004F9E 100%), linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0) 100%), url(${diagnosticBg})`,
          backgroundSize: "contain",
          pt: 130,
          px: 20,
          color: "white",
        }}
        justify="center"
      >
        <Grid sm={4} item sx={{}}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 300,
            }}
          >
            Hi {userName}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              maxWidth: 390,
            }}
          >
            Your Results Summary
          </Typography>
          <Typography
            sx={{
              fontSize: [16, 10],
              lineHeight: ["20px", "16px"],
              maxWidth: [, 210],
            }}
          >
            Data as of {formattedEndDate} at {formattedEndTime}
          </Typography>
        </Grid>
        <Grid
          item
          container
          sm={6}
          sx={{
            pb: 50,
          }}
        >
          <DiagnosticPieChart
            totalSpendAmount={totalSpent}
            opportunitiesIdentifiedAmount={totalSavings}
            pieChartData={pieChartData}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ pt: [, , , , 30], px: [20, 0] }}
        direction="column"
        alignItems="center"
      >
        <Grid item sm={10} xl={8}>
          <SavingOpportunities
            sx={{ mt: [40, 70] }}
            barChartData={barChartData}
          />
          <Top3Tickets
            sx={{ mt: [40, 70] }}
            tickets={topTickets}
            opCount={totalTickets}
          />
        </Grid>
      </Grid>
      <DiagnosticCTA opCount={totalTickets} />
    </Box>
  );
};
DiagnosticResults.displayName = "DiagnosticResults";
export default DiagnosticResults;
