/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  GridApi,
  GridCellParams,
  GridColDef,
  GridRowId,
  XGrid,
} from "@material-ui/x-grid";
import { NAVBAR_HEIGHT } from "app-constants";
import AdminDiagResultsPreview from "components/admin/AdminDiagResultsPreview";
import { format } from "date-fns";
import debounce from "debounce";
import {
  FlowStage,
  SetupTypes,
  useAdminSignUpsQuery,
  useSendResultsMutation,
} from "generated/graphql";
import { useSnackbar } from "notistack";
import numbro from "numbro";
import * as React from "react";

type Props = Record<string, never>;

interface ActionsData {
  flowId?: string;
  flowStage?: FlowStage | null;
  isAthena: boolean;
  onSendResultsClick: () => void;
  onViewResultsClick: () => void;
}

const SetupTypesPrettyName = {
  [SetupTypes.CloudAws]: "AWS",
  [SetupTypes.CloudGcp]: "GCP",
} as const;

export interface SignUpRow {
  id: string;
  companyName: string;
  jiraKey: string;
  userEmail: string;
  userName: string;
  flowSetupType: string;
  flowStage: string;
  flowProcessStart: string;
  flowProcessEnd: string;
  totalCost: string;
  totalSavings: string;
  percentSaving: string;
  actions: ActionsData;
}

const signUpsColumns: Array<GridColDef & { field: keyof SignUpRow }> = [
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    filterable: false,
    editable: false,
    disableColumnMenu: true,
    align: "right",
    width: 150,
    renderCell: (params) => {
      const {
        flowId,
        flowStage,
        onViewResultsClick,
      } = params.value as ActionsData;

      return (
        <>
          {flowId &&
            (flowStage === FlowStage.Processing ||
              flowStage === FlowStage.Results) && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onViewResultsClick}
                data-testid="view-results-button"
              >
                View results
              </Button>
            )}
        </>
      );
    },
    headerClassName: "header",
  },
  {
    field: "companyName",
    headerName: "Company",
    width: 130,
  },
  {
    field: "jiraKey",
    headerName: "JIRA Key",
    width: 100,
  },
  {
    field: "userName",
    headerName: "User name",
    width: 130,
  },
  {
    field: "userEmail",
    headerName: "User name",
    width: 230,
    renderCell: (params: GridCellParams) => (
      <Link color="primary" href={`mailto:${params.value}`}>
        {params.value}
      </Link>
    ),
  },
  {
    field: "flowSetupType",
    headerName: "Flow Setup Type",
    width: 160,
  },
  {
    field: "flowStage",
    headerName: "Stage",
  },
  {
    field: "flowProcessStart",
    headerName: "Start date",
    width: 160,
  },
  {
    field: "flowProcessEnd",
    headerName: "End date",
    width: 160,
  },
  {
    field: "totalCost",
    headerName: "Total Cost",
    width: 135,
  },
  {
    field: "totalSavings",
    headerName: "Total Savings",
    width: 135,
  },
  {
    field: "percentSaving",
    headerName: "Savings/Costs",
    width: 135,
  },
];

const AdminDashboard: React.FC<Props> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = React.useRef<GridApi>(null);
  const signUpsQuery = useAdminSignUpsQuery();

  const [currentRowId, setCurrentRowId] = React.useState<GridRowId | null>(
    null
  );

  const [search, setSearch] = React.useState("");

  const handleSearchChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
    // eslint-disable-next-line react-hooks/exhaustive-deps
  > = React.useCallback(
    debounce(
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(event.target.value?.trim());
      },
      200
    ),
    []
  );
  const sendResultsMutation = useSendResultsMutation();
  const handleViewResults = React.useCallback((rowId: string) => {
    setCurrentRowId(rowId);
  }, []);
  const handleNextPreviousClick = React.useCallback(
    (isPreviousClick = false) => {
      const api = gridRef.current;
      if (!api) throw new Error("Grid API is undefined!?");
      const orderedRowsIds = api.state.sorting.sortedRows;
      const orderedRowsIdsThatHaveResults: GridRowId[] = [];
      orderedRowsIds.forEach((rowId) => {
        const row = api.getRowFromId(rowId) as SignUpRow;
        if (
          row.flowStage === FlowStage.Processing ||
          row.flowStage === FlowStage.Results
        ) {
          orderedRowsIdsThatHaveResults.push(rowId);
        }
      });
      const currentRowIndex = orderedRowsIdsThatHaveResults.findIndex(
        (rowId) => currentRowId === rowId
      );

      const nextRowIndex = isPreviousClick
        ? currentRowIndex - 1
        : currentRowIndex + 1;
      const nextRowId = orderedRowsIdsThatHaveResults[nextRowIndex];
      if (!nextRowId) {
        enqueueSnackbar(`You reached the end of the list`, {
          variant: "info",
        });
      } else {
        setCurrentRowId(nextRowId);
      }
    },
    [currentRowId, enqueueSnackbar]
  );
  const handleSendResults = React.useCallback(
    async (flowId: string) => {
      try {
        await sendResultsMutation.mutateAsync({ flowId });
        await signUpsQuery.refetch();
        enqueueSnackbar("Sent results to customer with success!", {
          variant: "success",
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        enqueueSnackbar(
          `An error occurred while sending results to the customer: ${error}`,
          {
            variant: "error",
          }
        );
      }
    },
    [enqueueSnackbar, sendResultsMutation, signUpsQuery]
  );
  const signUpRows: SignUpRow[] = React.useMemo(() => {
    return (
      signUpsQuery.data?.admin?.companies?.edges.map<SignUpRow>(
        (edgeItem, index) => {
          const company = edgeItem?.node;
          const id = company?.id || index.toString();
          const user = company?.users?.edges[0]?.node;
          const flow = company?.flows?.edges[0]?.node;
          const cloud = flow?.cloud;
          const cloudProcessLength = cloud?.processes?.edges?.length || 0;
          const setupType = flow?.setupType || "";
          const flowSetupType =
            setupType in SetupTypesPrettyName
              ? SetupTypesPrettyName[
                  setupType as keyof typeof SetupTypesPrettyName
                ]
              : setupType || "";
          const process = cloud?.processes?.edges[cloudProcessLength - 1]?.node;
          const startDate =
            (process?.start &&
              format(new Date(process?.start), "MM/dd/yyyy hh:mm aaa")) ||
            "";
          const endDate =
            (process?.end &&
              format(new Date(process?.end), "MM/dd/yyyy hh:mm aaa")) ||
            "";
          const totalCost = cloud?.totalCost
            ? numbro(cloud.totalCost).formatCurrency({
                mantissa: 2,
                thousandSeparated: true,
              })
            : "";
          const totalSavings = cloud?.totalSavings
            ? numbro(cloud.totalSavings).formatCurrency({
                mantissa: 2,
                thousandSeparated: true,
              })
            : "";
          const ratioSaving =
            cloud?.totalSavings && cloud?.totalCost
              ? cloud?.totalSavings / cloud?.totalCost
              : 0;
          const percentSaving = ratioSaving
            ? `${Math.round(ratioSaving * 100)}%`
            : "";
          return {
            id,
            companyName: company?.name || "",
            jiraKey: company?.projectKey || "",
            userEmail: user?.email || "",
            userName: user?.name || "",
            flowSetupType,
            flowStage: flow?.stage || "",
            flowProcessStart: startDate,
            flowProcessEnd: endDate,
            totalCost,
            totalSavings,
            percentSaving,
            actions: {
              flowId: flow?.id,
              flowStage: flow?.stage,
              isAthena: flow?.setupType?.includes("savings_plan") || false,
              onSendResultsClick: () => {
                if (flow?.id) {
                  handleSendResults(flow?.id);
                }
              },
              onViewResultsClick: () => {
                handleViewResults(id);
              },
            },
          };
        }
      ) || []
    );
  }, [
    handleSendResults,
    handleViewResults,
    signUpsQuery.data?.admin?.companies?.edges,
  ]);

  const filteredRows = React.useMemo(() => {
    return search.length >= 2
      ? signUpRows.filter(
          ({
            companyName,
            jiraKey,
            userEmail,
            userName,
            flowSetupType,
            flowStage,
            flowProcessStart,
            flowProcessEnd,
            totalCost,
            totalSavings,
            percentSaving,
          }) => {
            return (
              companyName.includes(search) ||
              jiraKey.includes(search) ||
              userEmail.includes(search) ||
              userName.includes(search) ||
              flowSetupType.includes(search) ||
              flowStage.includes(search) ||
              flowProcessStart.includes(search) ||
              flowProcessEnd.includes(search) ||
              totalCost.includes(search) ||
              totalSavings.includes(search) ||
              percentSaving.includes(search)
            );
          }
        )
      : signUpRows;
  }, [search, signUpRows]);
  const currentRow = React.useMemo(() => {
    if (!currentRowId || !signUpRows) {
      return undefined;
    }
    return signUpRows.find(({ id }) => id === currentRowId);
  }, [currentRowId, signUpRows]);

  return (
    <>
      <AdminDiagResultsPreview
        onClose={() => setCurrentRowId(null)}
        onNext={() => handleNextPreviousClick(false)}
        onPrevious={() => handleNextPreviousClick(true)}
        rowData={currentRow}
      />
      <Container sx={{ mb: 4, mt: NAVBAR_HEIGHT + 24 }}>
        <Typography variant="h3" component="h1" sx={{ mb: 3 }}>
          Diagnostic Sign Ups
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 3 }}>
          Here are the sign ups of our customers.
        </Typography>
        <TextField
          onChange={handleSearchChange}
          variant="outlined"
          margin="normal"
          fullWidth
          id="input-search"
          label="Search..."
          name="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box sx={{ width: "100%", display: "flex" }}>
          <Box sx={{ flexGrow: 1 }}>
            <XGrid
              apiRef={gridRef as React.MutableRefObject<GridApi>}
              columnBuffer={signUpsColumns.length}
              pagination
              pageSize={15}
              rows={filteredRows}
              loading={signUpsQuery.isLoading}
              columns={signUpsColumns}
              disableSelectionOnClick
              autoHeight
              sx={{
                px: 3,
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
              }}
            />
          </Box>
        </Box>
      </Container>
    </>
  );
};
AdminDashboard.displayName = "AdminDashboard";
export default AdminDashboard;
