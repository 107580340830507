/** @jsxImportSource theme-ui */
import { Box, Button, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useAdminAuth } from "hooks/use-admin-auth";
import * as React from "react";

type Props = Record<string, never>;

const LoginPage: React.FC<Props> = () => {
  const { login } = useAdminAuth();
  const handleClick = () => {
    login();
  };
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h4" sx={{ mt: 3 }}>
          Cost Optimization Diagnostic
        </Typography>
        <Typography component="h2" variant="subtitle1" sx={{ mt: 3 }}>
          Admin Login
        </Typography>

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, px: 4 }}
          onClick={handleClick}
          size="large"
        >
          Sign In
        </Button>
      </Box>
    </Container>
  );
};
LoginPage.displayName = "LoginPage";
export default LoginPage;
