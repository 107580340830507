import axios, { AxiosResponse } from "axios";
import { useQuery, useMutation } from "react-query";
import env from "utils/env";

interface PutProps {
  file: File | null;
  uploadUrl: string;
}

export const fetchTemplate = () => {
  return axios
    .get(env("REACT_APP_ATHENA_SCRIPT_BUCKET"))
    .then(async (response: AxiosResponse) => {
      return JSON.stringify(response.data, null, 2);
    })
    .catch((error) => error.message);
};

export const putObject = async ({ file, uploadUrl }: PutProps) => {
  return axios
    .put(uploadUrl, file, {
      headers: {
        "Content-Type": "binary/octet-stream",
        processData: false,
      },
    })
    .then((response) => response.data)
    .catch((error) => error.message);
};
export const usePresignedUpload = () =>
  useMutation((payload: PutProps) => putObject(payload));
export const useTemplateQuery = () =>
  useQuery<string, string>("script", fetchTemplate);
