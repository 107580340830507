import CrispChat from "components/general/CrispChat";
import FullScreenLoader from "components/general/FullScreenLoader";
import DefaultLayout from "components/layout/DefaultLayout";
import { SetupTypes } from "generated/graphql";
import useAnalytics from "hooks/use-analytics";
import { AuthProvider, useAuth } from "hooks/use-auth";
import useRequireAthenaAuth from "hooks/use-require-athena-auth";
import useRequireAuth from "hooks/use-require-auth";
import AthenaResultsProcessing from "pages/AthenaResultsProcessing";
import AWSSetupResults from "pages/AWSSetupResults";
import ChooseRegionsPage from "pages/ChooseRegions";
import ConnectAthenaCFPage from "pages/ConnectAthenaCFPage";
import ConnectAthenaCSVPage from "pages/ConnectAthenaCSVPage";
import ConnectAthenaPage from "pages/ConnectAthenaPage";
import ConnectAthenaQueryPage from "pages/ConnectAthenaQueryPage";
import ConnectAthenaSQLPage from "pages/ConnectAthenaSQLPage";
import ConnectAwsPage from "pages/ConnectAwsPage";
import ConnectGcpPage from "pages/ConnectGcpPage";
import ConnectPage from "pages/ConnectPage";
import DiagnosticDashboardPage from "pages/DiagnosticDashboardPage";
import DiagnosticsAthenaPage from "pages/DiagnosticsAthenaPage";
import SigninupPage from "pages/SigninupPage";
import VerifyCodePage from "pages/VerifyCodePage";
import VerifySetupAthenaPage from "pages/VerifySetupAthenaPage";
import VerifySetupAWSPage from "pages/VerifySetupAWSPage";
import VerifySetupGCPPage from "pages/VerifySetupGCPPage";
import queryString from "query-string";
import * as React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import { authPaths } from "./routes";

const AthenaRoutes = () => {
  useRequireAthenaAuth();
  return (
    <Switch>
      <Route
        path={authPaths.SIGNIN}
        exact
        render={() => <SigninupPage variant="signin" />}
      />
      <Route
        path={authPaths.SIGNUP}
        exact
        render={() => <SigninupPage variant="signup" />}
      />
      <Route path={authPaths.VERIFY_CODE} component={VerifyCodePage} />
      <Route exact path={authPaths.CONNECT} component={ConnectAthenaPage} />
      <Route
        exact
        path={`${authPaths.CONNECT}/${SetupTypes.SavingsPlanQuery}/1`}
        component={ConnectAthenaQueryPage}
      />
      <Route
        exact
        path={`${authPaths.CONNECT}/${SetupTypes.SavingsPlanQuery}/2`}
        component={ConnectAthenaSQLPage}
      />
      <Route
        exact
        path={`${authPaths.CONNECT}/${SetupTypes.SavingsPlanQuery}/3`}
        component={ConnectAthenaCSVPage}
      />
      <Route
        path={`${authPaths.CONNECT}/${SetupTypes.SavingsPlanAthena}`}
        component={ConnectAthenaCFPage}
      />
      <Route
        path={`${authPaths.VERIFY_SETUP}/Athena`}
        component={VerifySetupAthenaPage}
      />
      <Route
        path={authPaths.ATHENA_RESULTS_PROCESSING}
        component={AthenaResultsProcessing}
      />
      <Route
        path={authPaths.DIAGNOSTIC_DASHBOARD}
        component={DiagnosticsAthenaPage}
      />
    </Switch>
  );
};

const CORoutes = () => {
  useRequireAuth();

  return (
    <Switch>
      <Route
        path={authPaths.SIGNIN}
        exact
        render={() => <SigninupPage variant="signin" />}
      />
      <Route
        path={authPaths.SIGNUP}
        exact
        render={() => <SigninupPage variant="signup" />}
      />
      <Route path={authPaths.VERIFY_CODE} component={VerifyCodePage} />
      <Route exact path={authPaths.CONNECT} component={ConnectPage} />
      <Route
        path={`${authPaths.CONNECT}/${SetupTypes.CloudAws}`}
        component={ConnectAwsPage}
      />
      <Route
        path={`${authPaths.CONNECT}/${SetupTypes.CloudGcp}`}
        component={ConnectGcpPage}
      />

      <Route
        path={`${authPaths.VERIFY_SETUP}/${SetupTypes.CloudAws}`}
        component={VerifySetupAWSPage}
      />
      <Route
        path={`${authPaths.VERIFY_SETUP}/${SetupTypes.CloudGcp}`}
        component={VerifySetupGCPPage}
      />

      <Route
        path={authPaths.AWS_RESULTS_PROCESSING}
        component={AWSSetupResults}
      />
      <Route path={authPaths.CHOOSE_REGIONS} component={ChooseRegionsPage} />
      <Route
        path={authPaths.DIAGNOSTIC_DASHBOARD}
        component={DiagnosticDashboardPage}
      />
    </Switch>
  );
};

const FlowsRoutesInner = () => {
  useAnalytics();
  const location = useLocation();
  const [isAthena, setIsAthena] = useLocalStorageState<boolean>(
    "v_athena",
    false
  );
  const { user, isLoading, signout, token } = useAuth();
  const isAuthenticated = !!user;

  const handleSignout = React.useCallback(() => {
    if (!token) {
      throw new Error(`The token should be defined`);
    }
    localStorage.removeItem("cloud-flow");
    signout?.mutate({
      token,
    });
  }, [signout, token]);

  const query = {
    ...queryString.parse(location.search),
  };
  const athena = query.athena === "true";

  if (query.athena) {
    if (athena && !isAthena) {
      setIsAthena(true);
    }
    if (!athena && isAthena) {
      setIsAthena(false);
    }
  }
  if (isLoading) {
    return <FullScreenLoader />;
  }
  return (
    <DefaultLayout handleSignout={isAuthenticated ? handleSignout : undefined}>
      {!isAthena ? <CORoutes /> : <AthenaRoutes />}
      {isAuthenticated ? <CrispChat /> : null}
    </DefaultLayout>
  );
};

const FlowsRoutes = () => {
  return (
    <AuthProvider>
      <FlowsRoutesInner />
    </AuthProvider>
  );
};

export default FlowsRoutes;
