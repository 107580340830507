/** @jsxImportSource theme-ui */
import DiagnosticResults from "components/diagnostic/DiagnosticResults";
import { useAuth } from "hooks/use-auth";
import * as React from "react";

const DiagnosticDashboardPage: React.FC = () => {
  const { user, flow } = useAuth();
  const flowId = flow?.id || "";

  return <DiagnosticResults flowId={flowId} userName={user?.name} />;
};
DiagnosticDashboardPage.displayName = "DiagnosticDashboardPage";
export default DiagnosticDashboardPage;
