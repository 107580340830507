/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource theme-ui */
import { Grid, Typography, Link } from "@material-ui/core";
import React from "react";
import ConnectContainer from "components/connect/ConnectContainer";
import Hero from "components/onboarding/Hero";
import {
  AthenaSqlMutationVariables,
  useAthenaSqlMutation,
} from "generated/graphql";
import { useAuth } from "hooks/use-auth";
import { ToSDialog } from "components/general/ToS";
import ConnectAthenaForm from "components/athena/ConnectAthenaForm";

const HeroSubtitle: React.FC = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <>
      <Typography sx={{ mt: 4 }}>
        Please review
        <Link
          component="button"
          variant="body1"
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            setOpen(true);
          }}
          sx={{ ml: 2 }}
        >
          Terms of Service.
        </Link>
      </Typography>
      <ToSDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
const ConnectAthenaQueryPage: React.FC = () => {
  const sqlMutation = useAthenaSqlMutation();
  const { flow, profileQuery } = useAuth();
  const handleSubmit = async (payload: AthenaSqlMutationVariables) => {
    await sqlMutation.mutateAsync({ ...payload, flowId: flow?.id || "" });
    profileQuery?.refetch();
  };
  return (
    <ConnectContainer>
      <Grid
        container
        justify="center"
        sx={{ height: "100%", minHeight: "587px" }}
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          sx={{
            bg: "white",
            borderRadius: 10,
            pt: [20, 0],
          }}
        >
          <Grid
            sm={4}
            md={6}
            lg={6}
            item
            container
            justify="center"
            alignItems="flex-start"
            sx={{ mt: 4 }}
          >
            <Grid item xs={12} md={10} sx={{ my: "auto" }}>
              <Hero title="Collecting Cost and Usage Data">
                <HeroSubtitle />
              </Hero>
              <Typography variant="body1" sx={{ mt: 4, fontWeight: 400 }}>
                In order to find the optimum level of for the AWS Savings Plan,
                we will need access to the Cost and Usage (CUR) data. If your
                accounts are not configured for CUR, please refer to the link to
                setup CUR.
              </Typography>
              <Link component="button" variant="body1" sx={{ mt: 2 }}>
                Set up Automated Cost and Usage reporting
              </Link>
            </Grid>
          </Grid>
          <ConnectAthenaForm
            data={{ sql: sqlMutation.data?.AthenaSQL?.sql || "" }}
            onSubmit={handleSubmit}
            isLoading={sqlMutation.isLoading}
          />
        </Grid>
      </Grid>
    </ConnectContainer>
  );
};
export default ConnectAthenaQueryPage;
