import { Grid } from "@material-ui/core";
import NavBar from "components/navigation/NavBar";
import React from "react";

type DefaultLayoutProps = {
  handleSignout?: () => void;
};

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  children,
  handleSignout,
}) => {
  return (
    <Grid container direction="column" item xs={12}>
      <NavBar handleSignout={handleSignout} />
      {children}
    </Grid>
  );
};
export default DefaultLayout;
